// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.utility-nav {
  max-width: 180px;
}

nav.navbar-subsite {
  min-height: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/header/header.component.scss"],"names":[],"mappings":"AAAA;EAEE,gBAAA;AAAF;;AAIA;EACE,eAAA;AADF","sourcesContent":["div.utility-nav {\r\n\r\n  max-width: 180px;\r\n\r\n}\r\n\r\nnav.navbar-subsite {\r\n  min-height: 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
