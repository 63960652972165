// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome-module {
  padding: 10px 7px;
}
.welcome-module span {
  text-align: left;
  font-size: 0.8em;
  width: 100%;
  display: block;
  line-height: 1.2em;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .welcome-module {
    padding: 10px 7px;
  }
}
/* Extra Small and below */
@media (max-width: 767px) {
  .welcome-module {
    padding: 16px 11px;
  }
  .welcome-module h1 {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/welcome/welcome.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AAAI;EACI,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;AAER;;AAEA,0CAAA;AAEA;EACI;IACI,iBAAA;EAAN;AACF;AAGA,0BAAA;AAEA;EACI;IACI,kBAAA;EAFN;EAGM;IACI,aAAA;EADV;AACF","sourcesContent":[".welcome-module {\n    padding: 10px 7px;\n    span{\n        text-align: left;\n        font-size: 0.8em;\n        width:100%;\n        display: block;\n        line-height: 1.2em;\n    }\n}\n\n/* Small devices (tablets, 768px and up) */\n\n@media (min-width: 768px) {\n    .welcome-module {\n        padding: 10px 7px;  \n    }\n}\n\n/* Extra Small and below */\n\n@media (max-width: 767px) {\n    .welcome-module {\n        padding: 16px 11px;\n        h1 {\n            display: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
