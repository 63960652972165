import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { DeltaReport, Activity, Instance, Project } from '#models/index';
import { ProjectService, ActivityService, InstanceService } from '#services/api';
import { AsyncCommunicationService } from '#services/shared';
import { environment } from '../../environments/environment';

import { Subject, EMPTY, Subscription, firstValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, concatMap, finalize } from 'rxjs/operators';
import * as bigInt from 'big-integer';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-synchronization',
  templateUrl: './synchronization.component.html',
  styleUrls: ['./synchronization.component.scss']
})
export class SynchronizationComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  projectQueryparam;
  initialDownload = false;
  report: DeltaReport;
  filterReport: DeltaReport;
  pieData: any;

  syncData1: any;
  syncData2: any;

  detailMessage: string;

  currentProject: Project;
  projectList: Project[];
  projectsLoaded: boolean = false;

  currentInstance: Instance;
  instancesLoaded: boolean = false;
  instanceList: Instance[];

  showPrimaveraErrorModal: boolean = false;
  showUploadPreview = false;

  term$ = new Subject<string>();
  wbsTerm$ = new Subject<string>();
  searchText: string = '';
  WbsSearch: string = '';

  expandTab: boolean = true;
  WBSGrouping: boolean = true;
  approvedCount: number;
  rejectedCount: number;
  cantworkCount: number;
  noverificationCount: number;
  notTouchedCount: number;
  didLoadParams: boolean = false;

  private searchSubscription: Subscription;

  constructor(
    private router: Router,
    private messageService: MessageService,
    private confirmationSvc: ConfirmationService,
    private loadingIndicatorSvc: LoadingIndicatorService,
    private activitySvc: ActivityService,
    private projectSvc: ProjectService,
    private activatedRoute: ActivatedRoute,
    private instanceSvc: InstanceService,
    private translate: TranslateService,
    private asyncService: AsyncCommunicationService
  ) {
    this.searchSubscription = this.term$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(term => {
          this.searchText = term;
          return EMPTY;
        })
      )
      .subscribe();

    this.searchSubscription = this.wbsTerm$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(term => {
          this.WbsSearch = term;
          this.filterActivitiesOnWBSGrouping(term);
          return EMPTY;
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.projectQueryparam = this.activatedRoute.snapshot.queryParamMap.has('projectObjectId')
    ? parseInt(this.activatedRoute.snapshot.queryParamMap.get('projectObjectId'), 10)
    : undefined;

    this.getInstanceMulticast();

  }

  getInstanceMulticast(){
    this.instanceSvc
    .getInstancesMulticast()
    .subscribe((data: Instance[]) => {
      (async () => {
      this.instanceList = data;
      if (data && data.length > 0) {
        if(this.projectQueryparam) {
          this.loadProjects();
        } else {
          const selectedInstance = parseInt( localStorage.getItem('selectedInstance'));
          if (selectedInstance) {
            this.currentInstance = data.find( x => x.Id === selectedInstance)
          } else {
            this.currentInstance = data[0];
          }
          localStorage.setItem('selectedProject', '');
          localStorage.setItem('selectedProjectId', '');
          this.asyncService.clearProject();

        }
        this.instancesLoaded = true;
      }
     })();
    });
  }

  loadProjects() {
    if (this.projectsLoaded) {
      return;
    }

    if (this.projectQueryparam && !this.currentProject && !this.didLoadParams) {
      const queryParamInstanceId = this.getInstanceId(this.projectQueryparam);
      this.currentInstance = this.instanceList.find(e => e.Id === queryParamInstanceId);
      this.didLoadParams = true;
    }

    let instanceId = null;
    if (this.currentInstance && this.currentInstance.Id > 0) {
      instanceId = this.currentInstance.Id;
    }

    this.loadingIndicatorSvc.show();

    this.projectSvc
      .getProjectList(instanceId).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe({
        next:(data: Project[]) => {
         this.PopulateProjectData(data);
        if(this.projectQueryparam) {
          this.currentProject = data.find(e => e.Id === this.projectQueryparam);

          this.getProjectData();
        }
      },
      error:() => (this.showPrimaveraErrorModal = true)
    });
  }

  resetProjects() {
    this.projectsLoaded = false;
    this.projectList = [];
    localStorage.setItem('selectedInstance', this.currentInstance.Id.toString());
    this.validateLanguage();
  }

  async validateLanguage() {
    const selectedInstance = parseInt( localStorage.getItem('selectedInstance'));
    let languageSelectionEnabled = environment.TCOInstanceId.includes((selectedInstance).toString());
    if(!languageSelectionEnabled) {
      this.translate.use('en');
    }
  }

  PopulateProjectData(data: Project[]) {
    this.projectList = data;
    this.projectsLoaded = true;
  }

  LoadOfflineProject() {
    const instanceId = localStorage.getItem("selectedInstance");
    this.showPrimaveraErrorModal = false;
    this.loadingIndicatorSvc.show();
    this.projectSvc
      .getBackendSchedulerProjects(instanceId).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe({
        next:(data: Project[]) => this.PopulateProjectData(data),
        error:(e) => this.DisplayRetrieveProjectsError(e)
    });
  }

  private DisplayRetrieveProjectsError(e: any) {
    this.showError(e, this.translate.instant('SYNCHRONIZATION.FailedRetrieve'));
  }

  getProjectData(): void {

    if (this.currentProject == null) {
      this.syncData1 = this.syncData2 = null;
      this.report = null;
      this.asyncService.clearProject();
      return;
    }

    this.asyncService.sendProject(this.currentProject.InstanceId);
    localStorage.setItem('selectedProject', this.currentProject.Name);
    localStorage.setItem('selectedProjectId', this.currentProject.Id.toString());

    this.loadingIndicatorSvc.show();
    this.updateQueryParameters(this.currentProject.Id);

    this.projectSvc
      .getSyncInfo(this.currentProject.Id).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(
        value => {
          if (value instanceof Array && value.length > 0) {
            if (value[0] == null || value[0].LastDownloadTime === null || value[0].LastDownloadTime === undefined) {
              this.syncData1 = null;
            } else {
              this.syncData1 = {
                UserName: value[0].User.Email,
                LastAttempt: value[0].LastDownloadTime
              };
            }

            if (value[1] == null || value[1].LastUploadTime === null || value[1].LastUploadTime ===  undefined) {
              this.syncData2 = null;
            } else {

              this.syncData2 = {
                UserName: value[1].User.Email,
                LastAttempt: value[1].LastUploadTime
              };
            }

            this.getReport();
          } else {
            this.syncData1 = this.syncData2 = null;
            this.report = null;
            this.showProjectNotLoadedWarning();
          }
        }
      );
  }

  async showProjectNotLoadedWarning(): Promise<void> {
    this.confirmationSvc.confirm({
      header: await firstValueFrom(this.translate.get('SYNCHRONIZATION.InitialRequired')),
      message: await firstValueFrom(this.translate.get('SYNCHRONIZATION.ProjectNotLoaded', {projectId: this.currentProject.ProjectId, projectName: this.currentProject.Name})),
      accept: () => {
        this.loadingIndicatorSvc.show();
        this.initialDownload = true;
        this.projectSvc
          .downloadNonProjectData(this.currentInstance.Id).pipe(
          finalize(() => {
            this.loadingIndicatorSvc.hide();
            this.router.navigate([`admin/projects/${this.currentProject.Id}`]);
          }))
          .subscribe(
            data => {
              this.messageService.add({
                severity: 'info',
                summary: this.translate.instant('SYNCHRONIZATION.DataUpdated'),
                detail: this.translate.instant('SYNCHRONIZATION.ResourcesUpdated')
              });
            }
          );
      },
      reject: () => {
        this.currentProject = null;
      }
    });
  }

  getReport(): void {
    if (this.currentProject == null) {
      this.report = null;
      return;
    }

    this.loadingIndicatorSvc.show();

    this.projectSvc
      .getDeltaReport(this.currentProject.Id).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(
        (data: DeltaReport) => {
          this.report = data;
          this.filterReport = data;
          if (!this.report.ApprovedActivities) {
            this.report.ApprovedActivities = [];
          }

          if (!this.report.RejectedActivities) {
            this.report.RejectedActivities = [];
          }

          if (!this.report.NotTouchedActivities) {
            this.report.NotTouchedActivities = [];
          }

          this.pieData = {
            labels: [this.translate.instant('SYNCHRONIZATION.Approved'), this.translate.instant('SYNCHRONIZATION.Rejected'), this.translate.instant('SYNCHRONIZATION.NoAction')],
            datasets: [
              {
                data: [data.ApprovedPercent, data.RejectedPercent, data.NotTouchedPercent],
                backgroundColor: ['#9CCC65', '#FF6384', '#DBDBDB'],
                hoverBackgroundColor: ['#9CCC65', '#FF6384', '#DBDBDB']
              }
            ]
          };
          this.readTotalActivities();
        }
      );
  }

  private showError(e, message: string) {
    const detail = e.error?.Message ? e.error.Message : e.message;
    this.messageService.add({
      severity: 'error',
      sticky: true,
      summary: message,
      detail: detail || ''
    });
  }

  download(): void {
    const globalSyncObservable = this.projectSvc.downloadNonProjectData(this.currentInstance.Id);
    const projectSyncObservable = this.projectSvc.downloadProjectData(this.currentProject.Id);
    const projectCacheObservable = this.projectSvc.rebuildProjectCache(this.currentProject.Id);

    const syncObservable = globalSyncObservable.pipe(concatMap(() => projectSyncObservable));

    const projectSyncedMsg = {
      severity: 'success',
      summary: this.translate.instant('SYNCHRONIZATION.ProjectSynced'),
      sticky: true
    };
    const cacheBuiltMsg = {
      severity: 'success',
      summary: this.translate.instant('SYNCHRONIZATION.CacheBuilt'),
      sticky: true
    };

    const loaderBody = this.translate.instant('SYNCHRONIZATION.DoNotClose');

    this.loadingIndicatorSvc.show(this.translate.instant('SYNCHRONIZATION.Synchronizing'), loaderBody);
    const syncSub = syncObservable.pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(() => {
        this.getProjectData();
        this.messageService.add(projectSyncedMsg);
        this.loadingIndicatorSvc.show(this.translate.instant('SYNCHRONIZATION.Building'),loaderBody);
        const cacheSub = projectCacheObservable.pipe(
          finalize(() => this.loadingIndicatorSvc.hide()))
          .subscribe(() => {
            this.messageService.add(cacheBuiltMsg);
          });
        this.subscriptions.push(cacheSub);
      });
    this.subscriptions.push(syncSub);
  }

  upload(): void {
    this.showUploadPreview = true;
  }

  onUploadCompleted() {
    this.getReport();
  }

  showActivityDeletionWarning(activities: Activity[]): void {
    this.detailMessage = '';
    activities.forEach(activity => {
      this.detailMessage = this.detailMessage + activity.ActivityId + ',\n';
    });
    this.detailMessage = this.detailMessage.substring(0, this.detailMessage.length - 2);
    this.confirmationSvc.confirm({
      header: this.translate.instant('SYNCHRONIZATION.SomeActivities'),
      message:
      this.translate.instant('SYNCHRONIZATION.TheFollowing') +
        this.detailMessage +
        this.translate.instant('SYNCHRONIZATION.AllOther'),
      acceptLabel: this.translate.instant('SYNCHRONIZATION.IAccept'),
      rejectVisible: false,
      accept: () => {
        this.getProjectData();
      }
    });
  }

  rejectActivity(activity: Activity): void {
    this.confirmationSvc.confirm({
      header: this.translate.instant('SYNCHRONIZATION.PleaseConfirm'),
      message: this.translate.instant('SYNCHRONIZATION.ConfirmReject') + activity.ActivityId + this.translate.instant('SYNCHRONIZATION.Activity?'),
      accept: () => {
        this.loadingIndicatorSvc.show();
        this.activitySvc
          .rejectActivity(activity).pipe(
          finalize(() => this.loadingIndicatorSvc.hide()))
          .subscribe(
            data => {
              activity.isApproved = null;
              activity.Comment = activity.rejectionComment;
              activity.CommentCount = (activity.CommentCount || 0) + 1;
              activity.InternalPercentComplete = Math.round(activity.PrimaveraPercentComplete * 100);

              const index = this.report.ApprovedActivities.indexOf(activity);
              this.report.ApprovedActivities.splice(index, 1);

              this.report.RejectedActivities.push(activity);

              this.report.ApprovedPercent = (this.report.ApprovedActivities.length * 100) / this.report.ActivityCount;
              this.report.RejectedPercent = (this.report.RejectedActivities.length * 100) / this.report.ActivityCount;
            }
          );
      }
    });
  }

  getActivityComment(event): void {
    this.loadingIndicatorSvc.show();
    const activity = event.activity;
    this.activitySvc
      .getComments(activity.Id).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe({
        next:(data) => {
          activity.Comments = data;
          event.sender.toggle(event.event);
        },
        error:(e) => {
          if (activity.Comments && activity.Comments.length > 0) {
            event.sender.toggle(event.event);
          }
          this.showError(e, this.translate.instant('SYNCHRONIZATION.FailedComments'));
        }
  });
  }

  onClickSendNotificationEmail() {
    this.loadingIndicatorSvc.show();
    this.activitySvc.sendNotificationEmail(this.currentProject.Id).pipe(
                    finalize(() => this.loadingIndicatorSvc.hide()))
                    .subscribe({
                      next:(data) => {
                        this.messageService.add({
                          severity: 'success',
                          sticky: true,
                          summary: 'Emails was sended correctly'
                        });
                      },
                      error:(e) => {
                        this.showError(e, 'Error sending the email, try again later');
                      }
  })
  }

  onClickConfigureProject() {
    this.router.navigate([`admin/projects/${this.currentProject.Id}`]);
  }

  onClickActivityReport() {
    this.router.navigate([`reporting/activityreport/${this.currentProject.Id}`]);
  }

  onClickActivityHistory() {
    this.router.navigate(['activityauditlog'], {queryParams: {projectObjectId: this.currentProject.Id}});
  }

  updateQueryParameters(projectObjectId: number) {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {
          projectObjectId: projectObjectId
        }
      });
  }

  getInstanceId(combinedId): number {
    const instanceId = bigInt(combinedId).and(0xFFFFFFFF00000000).shiftRight(32).add(1);
    return instanceId.toJSNumber();
  }

  toggleAccordionTab() {
    this.expandTab = !this.expandTab;
  }

  toggleWBSGrouping() {
    this.searchText = '';
    this.WbsSearch = '';
    if (this.WBSGrouping) {
      this.filterActivitiesOnWBSGrouping(this.WbsSearch);
    }
  }

  filterActivitiesOnWBSGrouping(keyword: string) {
    const reportToFilter = JSON.parse(JSON.stringify(this.report));
    keyword = keyword.toUpperCase();

    if (keyword === '' || keyword === undefined) {
      this.filterReport = reportToFilter;
      return;
    }

    const groupsArray = Object.keys(reportToFilter).filter(t => t.includes('GroupedActivities'));
    for (let i of groupsArray) {
      reportToFilter[i].forEach(group => {
        group.Jobs.forEach(job => {
          job.Activities = job.Activities.filter((act: Activity) => act.ActivityId.toUpperCase().includes(keyword)
                                                     || act.ActivityName.toUpperCase().includes(keyword)
                                                     || (act.PrimaryResource?.toUpperCase().includes(keyword))
                                                     || (act.Crew1?.toUpperCase().includes(keyword))
                                                     || (act.CoRep?.toUpperCase().includes(keyword))
                                                     || (act.Comment?.toUpperCase().includes(keyword))
                                                     );
          if (job.Activities.length === 0) {
            group.Jobs = group.Jobs.filter(t => t.Id !== job.Id);
          }

        });
      });
    }

    this.filterReport = reportToFilter;
  }

  readTotalActivities() {
    const fn = (equipmentClass) => equipmentClass.Jobs.reduce((acc: any, job: { Activities: string | any[]; }) => acc += job.Activities.length, 0);

    this.approvedCount = this.report.ApprovedGroupedActivities.reduce((total: number, group) => total += fn(group), 0);
    this.rejectedCount = this.report.RejectedGroupedActivities.reduce((total: number, group) => total += fn(group), 0);
    this.cantworkCount = this.report.CantWorkGroupedActivities.reduce((total: number, group) => total += fn(group), 0);
    this.noverificationCount = this.report.NoVerificationGroupedActivities.reduce((total: number, group) => total += fn(group), 0);
    this.notTouchedCount = this.report.NotTouchedGroupedActivities.reduce((total: number, group) => total += fn(group), 0);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
