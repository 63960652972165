// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .ui-dialog {
  white-space: pre-wrap;
}
::ng-deep .ui-dialog button {
  padding: 0px;
}

::ng-deep .ui-dialog.error-dialog {
  background-color: #f8d7da;
}
::ng-deep .ui-dialog.error-dialog div.ui-dialog-content, ::ng-deep .ui-dialog.error-dialog div.ui-dialog-footer, ::ng-deep .ui-dialog.error-dialog div.ui-dialog-titlebar {
  color: #721c24;
  background-color: #f8d7da;
  border: 0px;
}

::ng-deep .ui-dialog.warning-dialog {
  background-color: #fff3cd;
}
::ng-deep .ui-dialog.warning-dialog div.ui-dialog-content, ::ng-deep .ui-dialog.warning-dialog div.ui-dialog-footer, ::ng-deep .ui-dialog.warning-dialog div.ui-dialog-titlebar {
  color: #856404;
  background-color: #fff3cd;
  border: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/error-dialog/error-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AAAE;EACE,YAAA;AAEJ;;AAEA;EACE,yBAAA;AACF;AACE;EACE,cAAA;EACA,yBAAA;EACA,WAAA;AACJ;;AAGA;EACE,yBAAA;AAAF;AAEE;EACE,cAAA;EACA,yBAAA;EACA,WAAA;AAAJ","sourcesContent":["::ng-deep .ui-dialog {\n  white-space: pre-wrap;\n  button {\n    padding: 0px;\n  }\n}\n\n::ng-deep .ui-dialog.error-dialog {\n  background-color: #f8d7da;\n\n  div.ui-dialog-content\t, div.ui-dialog-footer, div.ui-dialog-titlebar {\n    color: #721c24;\n    background-color: #f8d7da;\n    border: 0px;\n  }\n}\n\n::ng-deep .ui-dialog.warning-dialog {\n  background-color: #fff3cd;\n\n  div.ui-dialog-content\t, div.ui-dialog-footer, div.ui-dialog-titlebar {\n    color: #856404;\n    background-color: #fff3cd;\n    border: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
