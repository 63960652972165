import { AuthService } from '#services/shared';
import { Component } from '@angular/core';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  userName: string;
  cai: string;
  constructor(private authService:AuthService) { }



  ngDoCheck(): void {
    this.userName = this.authService.getFullName();
  }
}
