import { Component, OnInit, EventEmitter, Input } from "@angular/core";
import { ErrorDialogService } from "#services/shared/error.dialog.service";
import { ErrorDialogModel } from "#models/errorDialogModel";
import { ApplicationInsightsService } from "helpers/ApplicationInsightsService";

@Component({
  selector: "app-error-dialog",
  templateUrl: "./error-dialog.component.html",
  styleUrls: ["./error-dialog.component.scss"],
})
export class ErrorDialogComponent implements OnInit {
  private subscription: EventEmitter<string>;
  @Input()
  id: string;
  public display: boolean;
  public model: ErrorDialogModel;

  subscription1: any;
  constructor(
    private service: ErrorDialogService,
    private appInsightService: ApplicationInsightsService
  ) {}
  ngOnInit() {
    this.service
      .getNotificationSubscription()
      .subscribe((cfg: ErrorDialogModel) => this.eventReceived(cfg));
  }

  public cssClass(): string {
    if (!this.model) {
      return;
    }

    switch (this.model.severity) {
      case "warn":
        return "warning-dialog";
      case "error":
        return "error-dialog";
      default:
        return "";
    }
  }

  private eventReceived(model: ErrorDialogModel): void {
    if (model.id !== this.id) {
      return;
    }
    this.display = true;
    this.model = model;
    // app insights error logging
    this.appInsightService.logException(
      new Error(
        `Status: ${this.model.errorStatus}, Message: ${this.model.detail}`
      )
    );
  }
}
