// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-config .container {
  padding-top: 15px;
  padding-bottom: 15px;
}

.row label {
  width: 100%;
}

.in-list {
  width: 100%;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 5px;
}

.list-sub-item {
  margin-left: 0.25em;
  font-weight: 500;
}

div.btn-container.labeled {
  padding-top: 20px;
}
div.btn-container.labeled-sm {
  padding-top: 25px;
}
div.btn-container.right {
  float: right;
  margin-left: 15px;
}
div.btn-container.left {
  float: left;
  margin-right: 15px;
}

.ui-dropdown-label {
  height: 35px;
}

.invalid {
  color: red;
}

.project-completed p-inputSwitch {
  vertical-align: middle;
}

:host ::ng-deep .ui-datepicker .ui-datepicker-prev span, :host ::ng-deep .ui-datepicker .ui-datepicker-next span {
  display: block !important;
  position: absolute;
  top: 3px !important;
  background-color: #f5f5f5;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/projects/projects.component.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,oBAAA;AAAJ;;AAKE;EACE,WAAA;AAFJ;;AAMA;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;AAHF;;AAMA;EACE,mBAAA;EACA,gBAAA;AAHF;;AAQE;EACE,iBAAA;AALJ;AAQE;EACE,iBAAA;AANJ;AASE;EACE,YAAA;EACA,iBAAA;AAPJ;AAUE;EACE,WAAA;EACA,kBAAA;AARJ;;AAYA;EACE,YAAA;AATF;;AAWA;EACE,UAAA;AARF;;AAYE;EACE,sBAAA;AATJ;;AAaA;EACE,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;AAVF","sourcesContent":[".project-config {\r\n  .container {\r\n    padding-top: 15px;\r\n    padding-bottom: 15px;\r\n  }\r\n}\r\n\r\n.row {\r\n  label {\r\n    width: 100%;\r\n  }\r\n}\r\n\r\n.in-list {\r\n  width: 100%;\r\n  font-weight: 600;\r\n  margin-bottom: 0;\r\n  margin-top: 5px;\r\n}\r\n\r\n.list-sub-item {\r\n  margin-left: .25em;\r\n  font-weight: 500\r\n}\r\n\r\ndiv.btn-container {\r\n\r\n  &.labeled {\r\n    padding-top: 20px;\r\n  }\r\n\r\n  &.labeled-sm {\r\n    padding-top: 25px;\r\n  }\r\n\r\n  &.right {\r\n    float: right;\r\n    margin-left: 15px;\r\n  }\r\n\r\n  &.left {\r\n    float: left;\r\n    margin-right: 15px;\r\n  }\r\n}\r\n\r\n.ui-dropdown-label {\r\n  height: 35px;\r\n}\r\n.invalid {\r\n  color:red;\r\n}\r\n\r\n.project-completed {\r\n  p-inputSwitch{\r\n    vertical-align: middle;\r\n  }\r\n}\r\n\r\n:host ::ng-deep .ui-datepicker .ui-datepicker-prev span, :host ::ng-deep .ui-datepicker .ui-datepicker-next span {\r\n  display: block !important;\r\n  position: absolute;\r\n  top: 3px !important;\r\n  background-color: #f5f5f5;\r\n  height: auto;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
