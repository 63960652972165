import { Activity } from './activity';

export class DeltaReport {

  ProjectId: number;
  ProjectCode: string;
  ProjectName: string;
  ActivityCount: number;
  Completed: boolean;

  ApprovedPercent: number;
  RejectedPercent: number;
  NotTouchedPercent: number;
  CantWorkPercent: number;
  NoVerificationPercent: number;

  ApprovedActivities: Activity[];
  RejectedActivities: Activity[];
  NotTouchedActivities: Activity[];
  CantWorkActivities: Activity[];
  NoVerificationActivities: Activity[];

  ApprovedGroupedActivities: EquipmentClass[];
  RejectedGroupedActivities: EquipmentClass[];
  NotTouchedGroupedActivities: EquipmentClass[];
  CantWorkGroupedActivities: EquipmentClass[];
  NoVerificationGroupedActivities: EquipmentClass[];

}

class EquipmentClass {
  EquipmentClassName: string;
  Jobs: ActivityJob[];
}

class ActivityJob {
  Name: string;
  Id: number;
  EquipmentClassName: string;
  EquipmentClassId: number;
  Activities: Activity[];
}
