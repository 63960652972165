import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { UploadPreviewDisplayData } from '#models/uploadPreviewDisplayData';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-activity-upload-grid',
  templateUrl: './activity.upload.grid.component.html',
  styleUrls: ['./activity.upload.grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityUploadGridComponent implements OnInit {
  cols: ({ field: string; header: string; filterBy?: string; } | { field: string; header: string; filterBy: string; })[] = [];
  tableData = new Array<UploadPreviewDisplayData>();

  @Input()
  set data(data: Array<UploadPreviewDisplayData> ) {
    if(!data || data.length === 0) {
      return;
    }
    this.tableData = data.filter(e => e.hasConflict);
  }

  constructor(private translate: TranslateService) {
  }

  async ngOnInit() {
    this.cols = [
      { field: 'activityId', header: await firstValueFrom(this.translate.get('COMPONENTS.ActivityId'))},
      { field: 'activityName', header: await firstValueFrom(this.translate.get('COMPONENTS.ActivityName'))},
      { field: 'oldPrimaveraPercent', header: await firstValueFrom(this.translate.get('COMPONENTS.CurrentPrimavera'))},
      { field: 'newPrimaveraPercent', header: await firstValueFrom(this.translate.get('COMPONENTS.MobileUpdate'))},
      { field: 'newActualStartDate', header: await firstValueFrom(this.translate.get('COMPONENTS.ActualStartDate'))},
      { field: 'newActualFinishDate', header: await firstValueFrom(this.translate.get('COMPONENTS.UpdatedActualDates'))}
    ];
  }
}
