// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  font-size: 0.9em;
}

body .table .table-tbody > tr:nth-child(even) {
  background-color: white;
}

.tr .th {
  color: #007ad9;
}

.non-scrollable-table .table-wrapper {
  overflow-x: visible !important;
}`, "",{"version":3,"sources":["webpack://./src/components/activityAuditLog/activityAuditLogGrid/activity.audit.log.grid.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,uBAAA;AAEF;;AAAA;EACE,cAAA;AAGF;;AAAA;EACE,8BAAA;AAGF","sourcesContent":["table {\n  font-size: .9em;\n}\nbody .table .table-tbody > tr:nth-child(even) {\n  background-color: white;\n}\n.tr .th {\n  color: #007ad9;\n}\n\n.non-scrollable-table .table-wrapper {\n  overflow-x: visible !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
