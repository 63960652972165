import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Project, Crew, CrewAssignment, User, Shift, PRIMARY_CREW, SUPPORTING_CREW, COMPANY_REPS, ActivityCodeType, ProjectInfo } from '#models/index';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { MessageService, ConfirmationService, SelectItemGroup } from 'primeng/api';
import { ProjectService, CrewAssignmentService, ProjectConfigurationService, ShiftService, ObsService, UserService } from '#services/api';
import { AuthService } from '#services/shared';
import { ActivityCodeTypeService } from '#services/api/activityCodeType.service';
import { Observable, forkJoin, Subscription, firstValueFrom } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ProjectInfoService } from '#services/api/projectInfo.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ErrorDialogModel } from '#models/errorDialogModel';
import { ErrorDialogService } from '#services/shared/error.dialog.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  loading: boolean = false;
  showNoTimeZoneModal: boolean = false;
  private params: any;
  isAdmin: boolean = false;
  initialDownload: boolean = false;
  configurationRequired: boolean = false;
  offlineMode: boolean;

  currentProject: Project;

  crewAssignments: CrewAssignment[];
  groupedCrews: SelectItemGroup[];

  coRepAssignments: CrewAssignment[];
  coRepCrews: SelectItemGroup[];

  crews: Crew[];
  projectShifts: Shift[];
  projectLocation: string;
  projectTimezone: string;
  projectPSREndtimeOffsetHour: number;
  projectPSREndtimeOffsetMinute: number;

  serverConfig: any;
  config: any = null;
  activityCodeTypes: ActivityCodeType[];
  activityCodeTypesLimited: ActivityCodeType[];
  groupedActivityCode: any[];
  groupedactivityCodeTypes: any[];
  projectTypes = [];
  contactInformation = [];
  updatePeriod = [];
  userList: User[];
  userName: string = '';
  projectInfo = new ProjectInfo();
  activeIndex = 5;

  displaySaveDialog = false;
  isProjectCompleted: boolean;
  confirmChangeDialogVisible = false;
  projectSelected: string = '';
  disablingTabs = false;

  public projectInfoForm = new FormGroup({
    projectTypes: new FormControl('', Validators.required),
    schedulerName: new FormControl(),
    startDate: new FormControl(),
    finishDate: new FormControl(),
    contactInformation: new FormControl(''),
    updatePeriod: new FormControl('')
  });

  formSubscription: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authSvc: AuthService,
    private loadingIndicatorSvc: LoadingIndicatorService,
    private messageSvc: MessageService,
    private confirmationSvc: ConfirmationService,
    private projectSvc: ProjectService,
    private assignmentSvc: CrewAssignmentService,
    private shiftSvc: ShiftService,
    private obsSev: ObsService,
    private configSvc: ProjectConfigurationService,
    private projectInfoSvc: ProjectInfoService,
    private activityCodeTypeSvc: ActivityCodeTypeService,
    private userSvc: UserService,
    private errorDialogSvc: ErrorDialogService,
    private translate: TranslateService
  ) {
    this.route.params.subscribe(params => {
      this.params = params;
    });
    this.config = this.configSvc.getEmptyConfig();
    this.isAdmin = this.userSvc.isSystemAdmin();
  }

  async ngOnInit() {
    this.projectTypes = [
      { label: await firstValueFrom(this.translate.get('PROJECT.Live')), value: 'Live' },
      { label: await firstValueFrom(this.translate.get('PROJECT.Test')), value: 'Test' }
    ];
    this.contactInformation = [
      { label: await firstValueFrom(this.translate.get('PROJECT.Email')), value: 'Email' },
      { label: await firstValueFrom(this.translate.get('PROJECT.Phone')), value: 'Phone' },
      { label: await firstValueFrom(this.translate.get('PROJECT.Chat')), value: 'Chat' }
    ];
    this.updatePeriod = [
      { label: await firstValueFrom(this.translate.get('PROJECT.RoundClock')), value: 'Round the clock' },
      { label: await firstValueFrom(this.translate.get('PROJECT.Days')), value: 'Days' }
    ];
    this.loadingIndicatorSvc.show();
    await this.getUsers();
    this.userName = this.authSvc.getFullName();

    this.projectSvc
      .getProject(this.params.id).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(
        (data: Project) => {
          if (data == null) {
            this.router.navigate(['/error/not-found'], { skipLocationChange: true });
          }
          this.currentProject = data;
          this.reloadProjectData();

          this.projectInfoSvc.getProjectInfo(this.currentProject.Id)
            .subscribe((projectInfo: ProjectInfo) => {
              if (projectInfo) {
                this.setProjectInfo(projectInfo);
              } else {
                const user = this.authSvc.getFullName();
                this.projectInfo.User = this.userList.find(userRecord => userRecord.Email === user);
                this.projectInfo.UserId = this.projectInfo.User.Id;
              }
            });
        }
      );
    this.onProjectoInfoChanges();
  }
  setProjectInfo(projectInfo) {
    this.projectInfo = projectInfo;
    this.isProjectCompleted = this.projectInfo.Completed;
    this.projectInfoForm.setValue({
      projectTypes: projectInfo.ProjectType,
      schedulerName: projectInfo.UserId ? this.userList.find(user => user.Id === projectInfo.UserId) : null,
      startDate: projectInfo.StartDate ? new Date(projectInfo.StartDate) : null,
      finishDate: projectInfo.FinishDate ? new Date(projectInfo.FinishDate) : null,
      updatePeriod: projectInfo.UpdatePeriod,
      contactInformation: projectInfo.ContactInformation
    });
    this.projectSelected = projectInfo.ProjectType;
    this.toggleControls(projectInfo.ProjectType);

    if (projectInfo.Completed) {
      Object.keys(this.projectInfoForm.controls).forEach(key => {
        this.projectInfoForm.get(key).disable();
      });
    }
  }

  private resetProjectData(): void {
    this.coRepAssignments = [];
    this.crewAssignments = [];
    this.activityCodeTypes = [];
    this.activityCodeTypesLimited = [];
    this.groupedActivityCode = [];
    this.groupedactivityCodeTypes = [];
    this.config = this.configSvc.getEmptyConfig();
    this.groupedCrews = [];
    this.crews = [];
    this.currentProject = null;
    this.projectShifts = [];
    this.projectLocation = '';
    this.projectTimezone = '';
    this.projectPSREndtimeOffsetHour = 0;
    this.projectPSREndtimeOffsetMinute = 0;
  }

  reloadProjectData(): void {
    if (this.currentProject == null) {
      this.resetProjectData();
      return;
    }

    const project = this.currentProject;

    // Obs timezone  obsSev
    this.loadingIndicatorSvc.show();
    this.obsSev
      .getObsTimezone(this.currentProject.ObsId).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe({
        next:(data: any) => {
          this.projectLocation = data['ProjectLocation'];
          this.projectTimezone = data['DisplayName'];
        },
        error:(e) => (this.showNoTimeZoneModal = true)
  });

    this.loadingIndicatorSvc.show();
    this.projectSvc
      .getSyncInfo(project.Id).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(
        value => {
          this.loadConfigurationData(project.Id);
        }
      );
  }

  private populateConfigData(data, projectId) {
    this.activityCodeTypes = data;
    this.activityCodeTypesLimited = data.filter(function (activityCodeType) {
      return (activityCodeType.Scope !== 'Global');
    });
    this.groupedactivityCodeTypes = this.groupByCodeType(this.activityCodeTypes);
    this.groupedActivityCode = this.groupByCodeType(this.activityCodeTypesLimited);
    this.configSvc
      .getProjectConfig(projectId).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(
        (config: any) => {
          this.serverConfig = config;
          this.config = this.configSvc.fillProjectConfig(config, this.activityCodeTypes);
          this.loadingIndicatorSvc.show();
          if (this.coRepsConfigured(this.serverConfig)) {
            this.loadCrews(projectId);
          }
        }
      );
  }

  private getOfflineCodeTypes(projectId: number): Observable<ActivityCodeType[]> {
    const projCodeTypesObservable = this.activityCodeTypeSvc.query({ projectObjectId: projectId });
    const globalCodeTypesObservable = this.activityCodeTypeSvc.query({ scope: 'Global' });
    const epsCodeTypesObservable = this.activityCodeTypeSvc.query({ scope: 'EPS' });

    const result = forkJoin([projCodeTypesObservable, globalCodeTypesObservable, epsCodeTypesObservable])
      .pipe(map((results) =>
        // flatten results
        results.reduce((arr, curr) => arr.concat(curr))
      ));
    return result;
  }

  private loadConfigurationData(projectId: number): void {
    // configuration
    this.loadingIndicatorSvc.show();

    // load activity codes from primavera and fill configuration dropdowns
    this.configSvc
      .searchActivityCodeTypesFromPrimavera(this.currentProject.Id).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe({
        next:(data) => {
          this.populateConfigData(data, projectId);
        },
        error:(e) => {
          this.messageSvc.add({
            severity: 'info',
            summary: this.translate.instant('PROJECT.LoadingOffline'),
            detail: this.translate.instant('PROJECT.FailedToLoad'),
            sticky: true
          });
          this.getOfflineCodeTypes(projectId)
            .subscribe(data => {
              this.offlineMode = true;
              this.populateConfigData(data, projectId);
            });
        }
  });

    // assignments
    this.loadingIndicatorSvc.show();
    this.assignmentSvc
      .getAssignmentList(projectId).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(
        (data: CrewAssignment[]) => {
          data.forEach(a => {
            this.assignmentSvc.fullfiilModel(a);
          });
          this.crewAssignments = data.filter(a => a.Crew.Type !== COMPANY_REPS);
          this.coRepAssignments = data.filter(a => a.Crew.Type === COMPANY_REPS);
        }
      );

    // shifts
    this.loadingIndicatorSvc.show();
    this.shiftSvc
      .getShiftList(projectId).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(
        (rets: Shift[]) => {
          this.projectShifts = [];
          this.projectPSREndtimeOffsetHour = 0;
          this.projectPSREndtimeOffsetMinute = 0;
          for (let i = 0; i < rets.length; i++) {
            const shift = new Shift();
            shift.init(rets[i]);
            this.projectShifts.push(shift);
            if (i === 0) {
              this.projectPSREndtimeOffsetHour = shift.EndTimeOffset.getHours();
              this.projectPSREndtimeOffsetMinute = shift.EndTimeOffset.getMinutes();
            }
          }
        }
      );
  }

  private loadCrews(projectId: number): void {
    this.loadingIndicatorSvc.show();
    this.assignmentSvc
      .getCrewListByProjectIdAndActivities(projectId).pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe(
        (data: Crew[]) => {
          this.splitCrewsInGroup(data);
        }
      );
  }

  private splitCrewsInGroup(data: Crew[]) {
    this.crews = data || [];
    this.groupedCrews = this.groupCrewsByType(this.crews.filter(c => c.Type !== COMPANY_REPS && c.IsDeleted === false));
    this.coRepCrews = this.groupCrewsByType(this.crews.filter(c => c.Type === COMPANY_REPS && c.IsDeleted === false));

    this.crews.forEach(it => {
      it.typeName = this.convertCrewType(it.Type);
    });
  }

  private convertCrewType(type: string): string {
    if (type === PRIMARY_CREW) {
      return this.translate.instant('PROJECT.PrimaryCrew');
    }

    if (type === SUPPORTING_CREW) {
      return this.translate.instant('PROJECT.SupportingCrew');
    }

    if (type === COMPANY_REPS) {
      return this.translate.instant('PROJECT.CompanyReps');
    }

    return type;
  }

  private convertScopeType(type: string): string {
    return type + this.translate.instant('PROJECT.Scope');
  }

  private groupCrewsByType(data: Crew[]): SelectItemGroup[] {
    const groups = {};
    data.forEach(crew => {
      groups[crew.Type] = groups[crew.Type] || { label: this.convertCrewType(crew.Type), value: crew.Type, items: [] };
      groups[crew.Type].items.push({ label: crew.Name, value: Object.assign({}, crew) });
    });
    const result: SelectItemGroup[] = [];
    for (const key in groups) {
      if (groups.hasOwnProperty(key)) {
        result.push(groups[key]);
      }
    }
    return result;
  }

  public groupByCodeType(data: any[]): SelectItemGroup[] {
    const groups = {};
    data.forEach(codeType => {
      groups[codeType.Scope] = groups[codeType.Scope] || { label: this.convertScopeType(codeType.Scope), value: codeType.Scope, items: [] };
      groups[codeType.Scope].items.push({ label: codeType.Name, value: Object.assign({}, codeType) });
    });
    const result: SelectItemGroup[] = [];
    for (const key in groups) {
      if (groups.hasOwnProperty(key)) {
        result.push(groups[key]);
      }
    }
    return result;
  }

  updateCrew(crew: Crew) {
    if (this.isProjectCompleted) {
      return;
    } else {
      this.loadingIndicatorSvc.show();
      this.assignmentSvc
        .updateCrew(crew).pipe(
        finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe(
          (data: any) => {
            crew = data;
            this.splitCrewsInGroup(this.crews);
          }
        );
    }
  }

  confirmSave() {
    if (!this.serverConfig.hasOwnProperty('TAW')) {
      this.saveConfig(true);
    } else if (this.config['Co-Reps'].Id === this.config['PA Job Number'].Id) {
      this.showError('warn', this.translate.instant('PROJECT.InvalidSelection'), this.translate.instant('PROJECT.ActivityCodeTypes'));
    } else {
      this.confirmChangeDialogVisible = true;
    }
    this.loading = true;

        setTimeout(() => {
            this.loading = false
        }, 2000);
  }

  saveConfig(confimation: boolean) {
    this.confirmChangeDialogVisible = false;
    if (!this.currentProject || !confimation) {
      return;
    }

    this.loadingIndicatorSvc.show();
    this.configSvc
      .saveProjectConfig(this.currentProject.Id, this.config).pipe(
      finalize(() => {
        this.loadingIndicatorSvc.hide();
      }))
      .subscribe(
        data => {
          this.reloadProjectData();
        }
      );
  }


  downloadNonProjectData(): void {
    this.loadingIndicatorSvc.show();
    this.projectSvc
      .downloadNonProjectData().pipe(
      finalize(() => {
        this.loadingIndicatorSvc.hide();
      }))
      .subscribe(
        data => {
          this.messageSvc.add({
            severity: 'info',
            summary: this.translate.instant('PROJECT.DataUpdated'),
            detail: this.translate.instant('PROJECT.ResourcesUpdated')
          });
        }
      );
  }

  refreshPage() {
    window.location.reload();
  }

  navigateToHome() {
    this.router.navigate(['']);
  }

  isSaveCodeTypesEnabled() {
    if (!this.serverConfig || !this.config || this.offlineMode) {
      return false;
    }
    const configChanged = Object.keys(this.config).some(e =>
      // Check if key was added
      (!this.serverConfig[e] && this.config[e] && this.config[e].Id) ||

      // Check if key was removed
      (this.serverConfig[e] && !this.config[e]) ||

      // Check if key was edited
      (this.config[e]?.Id && this.config[e].Id !== this.serverConfig[e].Id)
    );
    const result = configChanged && this.coRepsConfigured(this.config);
    return result;
  }

  public coRepsConfigured(dic): boolean {
    if (!dic) {
      return false;
    }

    const result = Boolean(dic[COMPANY_REPS]?.Id);
    return result;
  }

  public anyCrewConfigured(dic): boolean {
    if (!dic) {
      return false;
    }

    const result = [PRIMARY_CREW, SUPPORTING_CREW].some(key => dic[key]?.Id);
    return result;
  }

  public anyCrewAndCoRepsConfigured(): boolean {
    return this.coRepsConfigured(this.serverConfig) && this.anyCrewConfigured(this.serverConfig);
  }

  async getUsers() {
    try {
      const data: User[] = await firstValueFrom(this.userSvc.getAll());
      this.userList = data.filter(user => user.Email);
      this.userList.forEach(user => {
        user.fullName = `${user.FirstName} ${user.LastName}`;
      });
    } catch (e) {
      this.userList = [];
    }
  }

  addUser(event) {
    this.projectInfo.UserId = event.value ? event.value.Id : null;
  }

  isSaveProjectInfoEnabled(): boolean {
    let result = false;

    if (this.projectInfo) {
      result = this.projectInfo.ProjectType === 'Test' ||
            (
              this.projectInfo.ProjectType === 'Live'
              && this.projectInfo.UserId
              && this.projectInfo.ContactInformation
              && this.projectInfo.UpdatePeriod
              && this.projectInfo.StartDate
              && this.projectInfo.FinishDate
              && (this.projectInfo.StartDate < this.projectInfo.FinishDate)
            );
    }

      return result;
  }

  isActivityTypeCodesEnabled() {
    return this.projectInfo && this.projectInfo.ProjectId !== 0 && this.isSaveProjectInfoEnabled();
  }

  public saveProjectInfo() {
    this.loadingIndicatorSvc.show();
    if (this.projectInfo.ProjectId && this.projectInfo.ProjectId !== 0) {
      this.projectInfo.Completed = this.isProjectCompleted;
      this.projectInfoSvc
        .updateProjectInfo(this.projectInfo, this.currentProject.Id).pipe(
        finalize(() => {
          this.loadingIndicatorSvc.hide();
          this.disablingTabs = false;
          this.activeIndex = 3;
        }))
        .subscribe(
          projectInfo => {
            this.setProjectInfo(projectInfo);
          }
        );
    } else {
      this.projectInfo.ProjectId = this.currentProject.Id;
      this.projectInfoSvc
        .saveProjectInfo(this.projectInfo).pipe(
        finalize(() => {
          this.loadingIndicatorSvc.hide();
          this.disablingTabs = false;
          this.activeIndex = 3;
        }))
        .subscribe(
          projectInfo => {
            this.setProjectInfo(projectInfo);
          }
        );
    }

  }

  isTest() {
    return this.projectInfo.ProjectType === 'Test' || this.projectInfo.ProjectType === null;
  }

  statusCompletedDialogHandler(e) {
    if (this.projectInfo.Completed) {
      return;
    } else {
      this.displaySaveDialog = !this.displaySaveDialog;
    }
  }

  setToCompleted(value: boolean) {
    this.isProjectCompleted = value;
    this.statusCompletedDialogHandler(value);
    if (this.isProjectCompleted) {
      this.loadingIndicatorSvc.show();
      this.projectInfoSvc.
        setProjectAsCompleted(this.currentProject.Id).pipe(
        finalize(() => {
          this.loadingIndicatorSvc.hide();
        }))
        .subscribe(
          projectInfo => {
            this.setProjectInfo(projectInfo);
          }
        );
    }
  }
  onProjectoInfoChanges(): void {
    this.projectInfoForm.get('projectTypes').valueChanges.subscribe(val => {
      this.projectInfo.ProjectType = val;
    });
    this.projectInfoForm.get('contactInformation').valueChanges.subscribe(val => {
      this.projectInfo.ContactInformation = val;
    });
    this.projectInfoForm.get('schedulerName').valueChanges.subscribe(val => {
      this.projectInfo.UserId = val !== null ? val.Id : null;
    });
    this.projectInfoForm.get('startDate').valueChanges.subscribe(val => {
      this.projectInfo.StartDate = val;
    });
    this.projectInfoForm.get('finishDate').valueChanges.subscribe(val => {
      this.projectInfo.FinishDate = val;
    });
    this.projectInfoForm.get('updatePeriod').valueChanges.subscribe(val => {
      this.projectInfo.UpdatePeriod = val;
    });
  }

  showError(severity: string, title: string, errorMsg: string) {
    const msg = new ErrorDialogModel();
    msg.id = 'error-dialog';
    msg.severity = severity;
    msg.summary = title;
    msg.detail = errorMsg;
    this.errorDialogSvc.add(msg);
  }

  setProject() {
    this.projectSelected = this.projectInfoForm.get('projectTypes').value;
    this.setTabs(this.projectSelected);

    this.toggleControls(this.projectSelected);
  }

  private toggleControls(projectType: string) {
    if (projectType === 'Test') {
      this.projectInfoForm.get('schedulerName').disable();
      this.projectInfoForm.get('schedulerName').reset();

      this.projectInfoForm.get('startDate').disable();
      this.projectInfoForm.get('startDate').reset();

      this.projectInfoForm.get('finishDate').disable();
      this.projectInfoForm.get('finishDate').reset();

      this.projectInfoForm.get('contactInformation').disable();
      this.projectInfoForm.get('contactInformation').reset();

      this.projectInfoForm.get('updatePeriod').disable();
      this.projectInfoForm.get('updatePeriod').reset();
    } else {
      this.projectInfoForm.get('schedulerName').enable();
      this.projectInfoForm.get('startDate').enable();
      this.projectInfoForm.get('finishDate').enable();
      this.projectInfoForm.get('contactInformation').enable();
      this.projectInfoForm.get('updatePeriod').enable();
    }
  }

  private setTabs(projectType: string): void {
    this.disablingTabs = this.projectSelected === 'Live';
  }

  handleTabChange(e) {
    this.activeIndex = e.index;
  }
}
