import { Inject, Injectable } from '@angular/core';
import { LoadingIndicatorService } from './loading-indicator.service';
import { UserService } from '#services/api';
import { SCHEDULER, SYSTEM_ADMIN, User } from 'models';
import {
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from "@azure/msal-angular";
import { AuthenticationResult, RedirectRequest } from "@azure/msal-browser";
import { Observable,concatMap,map } from 'rxjs';
import { environment } from '#environments/environment';


@Injectable({
  providedIn: "root",
})
export class AuthService{
  constructor(
    private readonly _loadingIndicatorSvc: LoadingIndicatorService,
    private readonly _userService: UserService,
    private readonly _msalService: MsalService,

    @Inject(MSAL_GUARD_CONFIG)
    private readonly _msalGuardConfig: MsalGuardConfiguration
  ) {}

    token="";
    isIE = window.navigator.userAgent.indexOf("MSIE") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;



    getToken(): string {
      if(!this.isIE){
             this.token=sessionStorage.getItem('msal.idtoken');
      }
        else{
            this.token=document.cookie.split(';')[0].replace('msal.'+environment.msalConfig.auth.clientId+'.idtoken=','')// for IE
        }
          return this.token;
    }

    getAuthorizationHeader(): string {
      const token = this.getToken();
      return token ? `Bearer ${token}` : "";
    }

    getFullName(): string {

      return sessionStorage.getItem("FullName");

    }

    isAuthenticated(): boolean {
      return this._msalService.instance.getAllAccounts()?.length > 0 || false;
    }

    login(): void {
      if (this._msalGuardConfig.authRequest) {
        this._msalService.loginRedirect({
          ...this._msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this._msalService.loginRedirect();
      }
    }

    logout(): void {
      this._msalService.logoutRedirect();
      sessionStorage.clear();
    }

    getUserData(): Observable<any> {
      const account = this._msalService.instance.getActiveAccount();

      return this._msalService
        .acquireTokenSilent({
          scopes: ['user.read', environment.msalConfig.auth.user_impersonation],
          account: account,
        })
        .pipe(
          concatMap((tokenResponse: AuthenticationResult) => {
            sessionStorage.setItem("msal.idtoken", tokenResponse.idToken);
            this._userService.loadCurrentUserInfo();
            return this._userService.getCurrentUser().pipe(
              map((user: User) => {
                sessionStorage.setItem("FullName", tokenResponse.account.username);
                sessionStorage.setItem("CurrentUser",JSON.stringify(user));
                // this is for isSystemAdmin check
                if (!user?.ApplicationRoles) {
                  return false;
              }
             let sysAdmin = user.ApplicationRoles.find(role => role === SYSTEM_ADMIN);
             if(sysAdmin){
              sessionStorage.setItem("SystemAdmin","true")
             }else{
              sessionStorage.setItem("SystemAdmin","false");
             }
                // this is for Scheduler check
                if(!user?.ProjectRoles){
                  sessionStorage.setItem("Scheduler",'false');
                }
                let isSchedulers =  user.ProjectRoles.find(p => p.Roles.find(r => r === SCHEDULER)) ?? false;
                if(isSchedulers){
                  sessionStorage.setItem("Scheduler",'true');
                }else{
                  sessionStorage.setItem("Scheduler",'false');
                }

              })
            );
          })
        );
    }

}
