// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.synchronization-module h3,
.synchronization-module h4,
.synchronization-module h5 {
  margin-bottom: 12px;
}

.container {
  margin-bottom: 25px;
}

:host ::ng-deep .radio-button-ng .ui-radiobutton-icon {
  top: 0.15em !important;
  left: 2.6px !important;
}

:host ::ng-deep .ui-datepicker .ui-datepicker-prev span, :host ::ng-deep .ui-datepicker .ui-datepicker-next span {
  display: block !important;
  position: absolute;
  top: 3px !important;
  background-color: #f5f5f5;
  height: auto;
}

p-radiobutton {
  margin-right: 10px;
}

.filters .row {
  margin-bottom: 10px;
}

.date-input {
  padding-right: 10px;
}

:host ::ng-deep .ui-multiselect {
  padding: 0 0.2rem;
}

.reset label::before {
  content: "";
  display: inline-block;
}
.reset button {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/projects-management/projects-management.component.scss"],"names":[],"mappings":"AAEI;;;EAGE,mBAAA;AADN;;AAKE;EACE,mBAAA;AAFJ;;AAIE;EACE,sBAAA;EACA,sBAAA;AADJ;;AAIE;EACE,yBAAA;EACA,kBAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;AADJ;;AAIE;EACE,kBAAA;AADJ;;AAIE;EACE,mBAAA;AADJ;;AAIE;EACE,mBAAA;AADJ;;AAKI;EACI,iBAAA;AAFR;;AAQQ;EACI,WAAA;EACA,qBAAA;AALZ;AAQI;EACI,cAAA;AANR","sourcesContent":[".synchronization-module {\n\n    h3,\n    h4,\n    h5 {\n      margin-bottom: 12px;\n    }\n  }\n  \n  .container { \n    margin-bottom: 25px;\n  }\n  :host ::ng-deep .radio-button-ng .ui-radiobutton-icon {\n    top: 0.15em !important;\n    left: 2.6px !important;\n  }\n  \n  :host ::ng-deep .ui-datepicker .ui-datepicker-prev span, :host ::ng-deep .ui-datepicker .ui-datepicker-next span {\n    display: block !important;\n    position: absolute;\n    top: 3px !important; \n    background-color: #f5f5f5;\n    height: auto;\n  }\n  \n  p-radiobutton {\n    margin-right: 10px;\n  }\n  \n  .filters .row{\n    margin-bottom: 10px;\n  }\n  \n  .date-input {\n    padding-right: 10px;\n  }\n  \n  :host ::ng-deep {\n    .ui-multiselect{\n        padding: 0 .2rem;\n    }\n  }\n  \n  .reset {\n    label {\n        &::before {\n            content: \"\";\n            display: inline-block;\n        }\n    }\n    button {\n        display: block;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
