export class ProjectsManagement {
    constructor(
      public ProjectType: string = null,
      public ProjectId: number = 0,
      public ProjectName: string = '',
      public ProjectInstanceName: string = '',
      public StartDate: Date = null,
      public FinishDate: Date = null,
      public Completed: boolean = false,
      public Enabled: boolean = false) { }
  }
  