import { Component } from '@angular/core';
import { NotificationService } from '#services/shared/notification.service';
import { NotificationConfig } from '#models/notification-config';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent  {

  notificationConfig: NotificationConfig = new NotificationConfig();

  constructor(private notificationSvc: NotificationService) { }

  

  showSuccess(): void {
    this.notificationSvc.success(this.notificationConfig.message, 'notification-demo', this.notificationConfig.autoCloseTimeout);
  }

  showInfo(): void {
    this.notificationSvc.info(this.notificationConfig.message, 'notification-demo', this.notificationConfig.autoCloseTimeout);
  }

  showWarning(): void {
    this.notificationSvc.warning(this.notificationConfig.message, 'notification-demo', this.notificationConfig.autoCloseTimeout);
  }

  showError(): void {
    this.notificationSvc.error(this.notificationConfig.message, 'notification-demo', this.notificationConfig.autoCloseTimeout);
  }
}
