// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity-report-filter {
  width: 100%;
}
.activity-report-filter .right-align {
  display: block;
  float: right;
  position: relative;
}
.activity-report-filter .left-margin {
  margin-left: 5px;
}
.activity-report-filter .label {
  font-weight: bold;
}
.activity-report-filter .left-align {
  display: block;
  float: left;
}
.activity-report-filter .ui-button {
  padding: 2px 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/activity-report-filters/activity-report-filters.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAAE;EACE,cAAA;EACA,YAAA;EACA,kBAAA;AAEJ;AAAE;EACE,gBAAA;AAEJ;AAAE;EACE,iBAAA;AAEJ;AAAE;EACE,cAAA;EACA,WAAA;AAEJ;AAAE;EACE,2BAAA;AAEJ","sourcesContent":[".activity-report-filter {\n  width: 100%;\n  .right-align {\n    display: block;\n    float: right;\n    position: relative;\n  }\n  .left-margin {\n    margin-left: 5px;\n  }\n  .label {\n    font-weight: bold;\n  }\n  .left-align {\n    display: block;\n    float: left;\n  }\n  .ui-button {\n    padding: 2px 0px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
