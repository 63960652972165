// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .ui-calendar.ui-calendar-w-btn .ui-inputtext {
  width: 26rem;
}`, "",{"version":3,"sources":["webpack://./src/components/activityAuditLog/activityAuditLogFilter/activity.audit.log.filter.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF","sourcesContent":["body .ui-calendar.ui-calendar-w-btn .ui-inputtext {\n  width: 26rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
