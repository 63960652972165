// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity-report .container {
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0px !important;
  width: 100%;
}
.activity-report .group-activities .ui-accordion .ui-accordion-content {
  padding: 5px 0px !important;
}
.activity-report .activity-panel-header {
  font-weight: 300;
  padding-left: 5px;
  font-style: italic;
}
.activity-report .activity-info {
  font-size: 0.8em;
  font-weight: 300;
  font-style: italic;
}
.activity-report hr {
  margin: 10px 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/reporting/activity-report/activity-report.component.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,oBAAA;EACA,sBAAA;EACA,WAAA;AAAJ;AAGI;EACE,2BAAA;AADN;AAIE;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;AAFJ;AAIE;EACE,gBAAA;EACA,gBAAA;EACA,kBAAA;AAFJ;AAIE;EACE,gBAAA;AAFJ","sourcesContent":[".activity-report {\n  .container {\n    padding-top: 15px;\n    padding-bottom: 15px;\n    margin: 0px !important;\n    width: 100%;\n  }\n  .group-activities {\n    .ui-accordion .ui-accordion-content {\n      padding: 5px 0px !important;\n    }\n  }\n  .activity-panel-header {\n    font-weight: 300;\n    padding-left: 5px;\n    font-style: italic;\n  }\n  .activity-info {\n    font-size: 0.8em;\n    font-weight: 300;\n    font-style: italic;\n  }\n  hr {\n    margin: 10px 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
