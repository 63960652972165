export class WBS {
  Id: number;
  Name: string;
  ObsId: number;
  ProjectId: number;
  Status: string;
  Code: number;
  WBSCategoryId: number;
  ParentId: number;
}

export class WBSCategory {
  Name: string;
  SequenceNumber: number;
  Id: number;
}
