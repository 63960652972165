// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shiftGrid {
  margin: 1em;
}

.shiftSelect {
  margin: 0 1em 0 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25em;
  color: #55595c;
  line-height: 1.45;
  padding: 0.5em 0.75em;
}

.shiftSelectAlt {
  margin: 0 1em 0 1em;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25em;
  color: #55595c;
  line-height: 1.45;
  padding: 0.5em 0.75em;
}

.shiftInput {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25em;
  color: #55595c;
  line-height: 1.45;
  padding: 0.5em 0.75em;
}`, "",{"version":3,"sources":["webpack://./src/components/shift-grid/shift-grid.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,iBAAA;EACA,qCAAA;EACA,qBAAA;EACA,cAAA;EACA,iBAAA;EACA,qBAAA;AACF;;AAEA;EACE,mBAAA;EACA,qCAAA;EACA,qBAAA;EACA,cAAA;EACA,iBAAA;EACA,qBAAA;AACF;;AAEA;EACE,qCAAA;EACA,qBAAA;EACA,cAAA;EACA,iBAAA;EACA,qBAAA;AACF","sourcesContent":[".shiftGrid {\n  margin: 1em;\n}\n\n.shiftSelect {\n  margin: 0 1em 0 0;\n  border: 1px solid rgba(0,0,0,.15);\n  border-radius: .25em;  \n  color: #55595c;\n  line-height: 1.45;\n  padding: .5em .75em;\n}\n\n.shiftSelectAlt {\n  margin: 0 1em 0 1em;\n  border: 1px solid rgba(0,0,0,.15);\n  border-radius: .25em;  \n  color: #55595c;\n  line-height: 1.45;\n  padding: .5em .75em;\n}\n\n.shiftInput {\n  border: 1px solid rgba(0,0,0,.15);\n  border-radius: .25em;  \n  color: #55595c;\n  line-height: 1.45;\n  padding: .5em .75em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
