// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin-bottom: 1em;
}

h2 {
  margin-bottom: 0;
}

h3 {
  margin: 0;
}

:host ::ng-deep .customDialog {
  background-color: white !important;
  width: 50vw !important;
  box-shadow: 0px 20px 20px rgba(216, 221, 230, 0.5019607843);
  border: 1px solid rgb(231, 231, 231);
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/team-communication/team-communication.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,SAAA;AACF;;AAIE;EACI,kCAAA;EACA,sBAAA;EACA,2DAAA;EACA,oCAAA;AADN","sourcesContent":[".container {\n  margin-bottom: 1em\n}\n\nh2 {\n  margin-bottom: 0;\n}\n\nh3 {\n  margin: 0;\n}\n\n// DIALOG BOX\n:host {\n  ::ng-deep .customDialog {\n      background-color: white !important;\n      width: 50vw !important;\n      box-shadow: 0px 20px 20px #D8DDE680;\n      border: 1px solid rgb(231, 231, 231);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
