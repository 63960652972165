// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-dialog {
  overflow: scroll;
  max-height: 90%;
}`, "",{"version":3,"sources":["webpack://./src/components/activityUpload/activity.upload.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;AACF","sourcesContent":[".upload-dialog {\n  overflow: scroll;\n  max-height: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
