// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select {
  width: 45%;
  height: 5vh;
}

.container {
  margin-bottom: 1em;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/language/language.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":["select {\n  width: 45%;\n  height: 5vh;\n}\n\n.container {\n  margin-bottom: 1em\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
