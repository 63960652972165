import { Component } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { FeedbackService } from '#services/api';
import { Feedback } from '#models/feedback';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent  {
  public formVisible = false;
  public sending = false;

  public categories = ['Feedback', 'Improvement', 'Issue'];

  public feedbackForm = new FormGroup({
    text: new FormControl('', Validators.required),
    category: new FormControl(this.categories[0], Validators.required)
  });

  constructor(private feedbackSvc: FeedbackService) { }

  public showForm(): void {
    this.formVisible = true;
  }

  public hideForm(): void {
    this.formVisible = false;
  }

  public submitFeedback(): void {
    this.formVisible = false;

    const feedback: Feedback = {
      Comment: this.feedbackForm.get('text').value,
      Category: this.feedbackForm.get('category').value
    };

    this.sending = true;
    this.feedbackSvc.postFeedback(feedback).pipe(
      finalize(() => this.sending = false))
      .subscribe(() => this.feedbackForm.get('text').setValue(''));
  }
}
