import { Component } from "@angular/core";
import { ApplicationInsightsService } from "helpers/ApplicationInsightsService";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  constructor(private appInsightService: ApplicationInsightsService) {
    
      const user = sessionStorage.getItem("FullName");
      if (user) {
        this.appInsightService.setUser(user);
      }
    
  }
}
