import { Component, OnInit, OnDestroy } from '@angular/core';
import { MobileManagementService } from '#services/api/mobile-management.service';
import { MobileVersionModel, MobileVersionStatus } from '#models/mobile-management';
import { LoadingIndicatorService } from '#services/shared';
import { ErrorDialogModel } from '#models/errorDialogModel';
import { ErrorDialogService } from '#services/shared/error.dialog.service';
import { Constants } from '../../../constants';
import { finalize, firstValueFrom, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

const versionRegex = /^\d{1,2}\.\d{1,2}\.\d{1,3}$/;

@Component({
  selector: 'app-mobile-management',
  templateUrl: './mobile-management.component.html',
  styleUrls: ['./mobile-management.component.scss']
})
export class MobileManagementComponent implements OnInit, OnDestroy {
  versionList: MobileVersionModel[] = [];
  statusList: any[] = [];
  isAddMode = false;
  newVersion: MobileVersionModel = new MobileVersionModel();
  subscriptions = new Array<Subscription>();
  isEditMode: boolean;
  constructor(
    private mobileManageSvc: MobileManagementService,
    private loadingIndicatorSvc: LoadingIndicatorService,
    private errorDialogSvc: ErrorDialogService,
    private translate: TranslateService) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  ngOnInit() {
    this.generateStatusList();
    this.getAllVersions();
  }
  getAllVersions() {
    this.loadingIndicatorSvc.show();
    const getAllVersionSub = this.mobileManageSvc.getAllVersions().pipe(finalize(() => {
      this.loadingIndicatorSvc.hide();
    })).subscribe(versions => {
      this.versionList = versions;
      this.versionList.forEach(version => version.PreviousStatus = version.Status);
      this.versionList.sort(this.versionComparator);
    });
    this.subscriptions.push(getAllVersionSub);
  }

  versionComparator(a, b) {
    const v1 = a.Version.split('.');
    const v2 = b.Version.split('.');
    const len = Math.max(v1.length, v2.length);

    for (let i = 0; i < len; i++) {
      const _v1 = +v1[i] || 0;
      const _v2 = +v2[i] || 0;
      if (_v1 === _v2) { continue; } else { return _v1 > _v2 ? -1 : 1; }
    }
    return 0;
  }

  enableAddNewVersion() {
    this.isAddMode = true;
  }
  cancelAdd() {
    this.resetNewVersion();
    this.isAddMode = false;
  }
  resetNewVersion() {
    this.newVersion.Version = '';
    this.newVersion.Status = null;
  }

  async saveVersion(version: MobileVersionModel, isNew: boolean) {
    if (this.isVersionValid(version)) {
      if (this.versionList.length === 0 && version.Status !== MobileVersionStatus.Current) {
        this.showError('warn', Constants.BusinessErrorMsgTitle, await firstValueFrom(this.translate.get('MOBILEMANAGEMENT.OneVersion')));
        return;
      }
      if (isNew && this.isVersionExists(version)) {
        this.showError('warn', Constants.BusinessErrorMsgTitle, await firstValueFrom(this.translate.get('MOBILEMANAGEMENT.AlreadyExists', {version: version.Version})));
        return;
      }

      this.VersionCheck(version, isNew);

      this.loadingIndicatorSvc.show();
      const addVersionSub = this.mobileManageSvc.addOrUpdateVersion(version).pipe(
        finalize(() => { this.loadingIndicatorSvc.hide(); }))
        .subscribe({
          next:(res) => {
          res.PreviousStatus = res.Status;
          if (isNew) {
            this.resetNewVersion();
            this.isAddMode = false;
            this.versionList.unshift(res);
          } else {
            this.versionList = this.versionList.map(ele => ele.Version === res.Version ? res : ele);
            this.isEditMode = false;

          }
          this.versionList.sort(this.versionComparator);
        },
        error:(err) => {
          this.loadingIndicatorSvc.hide();
        }
      });
      this.subscriptions.push(addVersionSub);
    } else {
      this.showError('warn', Constants.BusinessErrorMsgTitle, await firstValueFrom(this.translate.get('MOBILEMANAGEMENT.InvalidVersion')));
    }
  }

  async VersionCheck(version:MobileVersionModel, isNew:boolean){
    if (version.Status === MobileVersionStatus.Current && this.isCurrentVersionExists(version, isNew)) {
      this.showError('warn', Constants.BusinessErrorMsgTitle, await firstValueFrom(this.translate.get('MOBILEMANAGEMENT.StatusVersion', {current: MobileVersionStatus.Current})));
      if (!isNew) {
        version.Status = version.PreviousStatus;
      }
      return;
    }
    if (version.Status !== MobileVersionStatus.Current && !this.isCurrentVersionExists(version, isNew)) {
      this.showError('warn', Constants.BusinessErrorMsgTitle, await firstValueFrom(this.translate.get('MOBILEMANAGEMENT.VersionWarning', {current: MobileVersionStatus.Current})));
    }
  }

  generateStatusList() {
    for (const key in MobileVersionStatus) {
      if (MobileVersionStatus.hasOwnProperty(key)) {
        this.statusList.push({ label: MobileVersionStatus[key], value: MobileVersionStatus[key] });
      }
    }
  }


  showError(severity: string, title: string, errorMsg: string) {
    const msg = new ErrorDialogModel();
    msg.id = 'error-dialog';
    msg.severity = severity;
    msg.summary = title;
    msg.detail = errorMsg;
    this.errorDialogSvc.add(msg);
  }

  isVersionExists(version: MobileVersionModel) {
    const exists = this.versionList.find(elem => elem.Version === version.Version);
    return exists ? true : false;
  }
  isCurrentVersionExists(version: MobileVersionModel, isNew: boolean) {
    let currentExists;
    if (isNew) {
      currentExists = this.versionList.find(elem => elem.Status === MobileVersionStatus.Current);
    } else {
      currentExists = this.versionList.find(elem => elem.Status === MobileVersionStatus.Current && elem.Version !== version.Version);
    }
    return currentExists ? true : false;
  }

  isVersionValid(version: MobileVersionModel): boolean {
    return version?.Version && version.Status && versionRegex.test(version.Version);
  }

  async deleteVersion(version: MobileVersionModel) {
    this.loadingIndicatorSvc.show();
    if (version.Status === MobileVersionStatus.Current) {
      this.showError('warn', Constants.Warning, await firstValueFrom(this.translate.get('MOBILEMANAGEMENT.DeletedVersion', {version: version.Version})));
    }
    this.mobileManageSvc.deleteVersion(version.Id).pipe(finalize(() => {
      this.loadingIndicatorSvc.hide();
    })).subscribe({
      next:(_) => {
      this.versionList = this.versionList.filter(ver => ver.Version !== version.Version);
    },
      error: (err) => {
      (async () => {
      this.showError('error', Constants.BusinessErrorMsgTitle, await firstValueFrom(this.translate.get('MOBILEMANAGEMENT.DeleteFailed')));
      })();
    }
  });
  }

  disableEditMode() {
    this.isEditMode = false;
  }

  editMode() {
    this.isEditMode = !this.isEditMode;
  }
}
