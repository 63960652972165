// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background: #ededee;
  padding: 20px 20px 10px 20px;
  font-size: 14px;
  text-align: center;
}
footer .copyright {
  font-size: 0.85em;
}

footer .navbar-brand {
  height: 100%;
}

footer .list-inline {
  margin-left: 0;
  margin-bottom: 20px;
}

footer .list-inline:last-child {
  margin-bottom: 0;
}

footer ul li {
  list-style-type: none;
}

footer a {
  font-weight: 900;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  footer .list-inline > li {
    border-right: 1px solid #dbdcdd;
    padding-right: 15px;
    padding-left: 12px;
    line-height: 1.5em;
  }
  footer .list-inline > li:last-child {
    border-right: none;
  }
}
/* Extra Small and below */
@media (max-width: 767px) {
  footer {
    text-align: center;
  }
  footer .navbar-brand {
    float: none;
    height: 100%;
    padding-bottom: 0;
  }
  footer .list-inline {
    margin-bottom: 0;
  }
  footer .list-inline > li {
    display: block;
    padding-right: 0;
    padding-left: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,4BAAA;EACA,eAAA;EACA,kBAAA;AACF;AACE;EACE,iBAAA;AACJ;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,cAAA;EACA,mBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AAGA,0CAAA;AACA;EACE;IACE,+BAAA;IACA,mBAAA;IACA,kBAAA;IACA,kBAAA;EAAF;EAGA;IACE,kBAAA;EADF;AACF;AAIA,0BAAA;AACA;EACE;IACE,kBAAA;EAFF;EAKA;IACE,WAAA;IACA,YAAA;IACA,iBAAA;EAHF;EAMA;IACE,gBAAA;EAJF;EAOA;IACE,cAAA;IACA,gBAAA;IACA,eAAA;EALF;AACF","sourcesContent":["footer {\n  background: #ededee;\n  padding: 20px 20px 10px 20px;\n  font-size: 14px;\n  text-align: center;\n\n  .copyright {\n    font-size: 0.85em;\n  }\n}\n\nfooter .navbar-brand {\n  height: 100%;\n}\n\nfooter .list-inline {\n  margin-left: 0;\n  margin-bottom: 20px;\n}\n\nfooter .list-inline:last-child {\n  margin-bottom: 0;\n}\n\nfooter ul li {\n  list-style-type: none;\n}\n\nfooter a {\n  font-weight: 900;\n}\n\n/* Small devices (tablets, 768px and up) */\n@media (min-width: 768px) {\n  footer .list-inline>li {\n    border-right: 1px solid #dbdcdd;\n    padding-right: 15px;\n    padding-left: 12px;\n    line-height: 1.5em;\n  }\n\n  footer .list-inline>li:last-child {\n    border-right: none;\n  }\n}\n\n/* Extra Small and below */\n@media (max-width: 767px) {\n  footer {\n    text-align: center;\n  }\n\n  footer .navbar-brand {\n    float: none;\n    height: 100%;\n    padding-bottom: 0;\n  }\n\n  footer .list-inline {\n    margin-bottom: 0;\n  }\n\n  footer .list-inline>li {\n    display: block;\n    padding-right: 0;\n    padding-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
